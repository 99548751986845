import React from "react";
import './home.css'
import ReactDOM from 'react-dom/client';
const Home=()=>{
  return(
    <>
    <p className='midh'>CPA for U.S. Resident, U.S. Expats and U.S. Nonresident Alien Foreign Nationals</p>
<p className='midt'>Protaxation is the world's leading U.S. individual international tax firm, specializing in the delivery of world class professional services in U.S. Expatriate tax, Foreign National/ U.S. Nonresident Alien tax and the U.S. taxation of High Net Worth individuals in tax accounting, consulting and compliance-related services.</p>
</>
  );
}
export default Home;